import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Authenticated,
  CanAccess,
  HttpError,
  Refine,
  useApiUrl,
  useCan,
  useCustom,
} from '@refinedev/core'
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar'
import dayjs from 'dayjs'
import { useNotificationProvider, ThemedLayoutV2, ThemedTitleV2 } from '@refinedev/antd'
import '@refinedev/antd/dist/reset.css'

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6'
import { spaceDataProvider } from 'dataProvider'
import { AppIcon } from 'components/app-icon'
import { Login } from 'pages/login'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { authProvider } from './authProvider'
import { Header } from './components/header'
import { ColorModeContextProvider } from './contexts/color-mode'

import { TOKEN_KEY, BACKEND_URL } from './constants'
import axios, { AxiosRequestConfig } from 'axios'
import { GatewaysList } from 'pages/gateways'
import { ProfileShow } from 'pages/profile'
import {
  DashboardPage,
  PartnersDashboardPage,
  MerchantsDashboardPage,
} from 'pages/dashboard'
import { BillingPage } from 'pages/billing/billing'
import { DevPage } from 'pages/dev'
import {
  ApiOutlined,
  BankOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  DoubleRightOutlined,
  FieldTimeOutlined,
  FileExcelOutlined,
  FileOutlined,
  GroupOutlined,
  MessageOutlined,
  MoneyCollectOutlined,
  PartitionOutlined,
  ShopOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  PullRequestOutlined,
  PropertySafetyOutlined,
  FunnelPlotOutlined,
  RadiusUpleftOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import { CardList } from 'pages/cards'
import { CardCreate, CardEdit } from 'pages/cards'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { MerchantCreate, MerchantEdit, MerchantList, MerchantShow } from 'pages/merchants'
import { ScoringClientList, ScoringClientShow } from 'pages/scoring_clients'
import { UserCreate, UserList, UserShow, UserEdit } from 'pages/users'
import { SmsList } from 'pages/sms'
import { OperationCreate, OperationsList } from 'pages/operations'
import { PartnerCreate, PartnerShow, PartnersList, PartnerEdit } from 'pages/partners'
import { TeamDepositsList, TeamDepositCreate } from 'pages/team_deposits'
import { TeamsList, TeamCreate, TeamEdit } from 'pages/teams'
import { PaymentCascadesList, PaymentCascadeShow, PaymentCascadeCreate, PaymentCascadeEdit } from 'pages/payment_cascades'
import { ShiftShow, ShiftsList, ShiftsSummary } from 'pages/shifts'
import { FeedEdit, FeedShow } from 'pages/feeds'
import { ReportsList, ReportCreate } from 'pages/reports'
import { ExportsList } from 'pages/exports'
import { PaymentRequisiteBlocksList } from 'pages/payment_requisite_blocks'
import { AgentsList, AgentCreate, AgentShow } from 'pages/agents'
import { AgreementsList, AgreementCreate } from 'pages/agreements'
import { accessControlProvider } from 'accessControlProvider'
import { CashinPage } from 'pages/cashin'
import { FeedItemShow } from 'pages/feed_items'
import { PayoutsList } from 'pages/payouts/list'
import { DisputesList, DisputeShow } from 'pages/disputes'
import { WebhooksList } from 'pages/webhooks'
import { Badge } from 'antd'
import { IDisputeBadge } from 'interfaces'
import { CallbacksList } from 'pages/callbacks'
import { EventsList } from 'pages/events'

import './styles.css'

require('dayjs/locale/ru')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ru')

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    if (request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.errors?.join(', '),
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  }
)

const DisputesBadge: React.FC<{}> = () => {
  const queryClient = useQueryClient()
  const { data: canListDisputes } = useCan({
    resource: 'disputes',
    action: 'list',
  })

  const apiUrl = useApiUrl()

  const { data } = useCustom<IDisputeBadge>({
    url: `${apiUrl}/disputes/badge`,
    method: 'get',
    queryOptions: {
      enabled: !!canListDisputes?.can,
      queryKey: ['disputes', 'badge'],
    },
  })

  useEffect(() => {
    const timer = setInterval(() => {
      queryClient.invalidateQueries(['disputes', 'badge'])
    }, 10000)
    return () => clearInterval(timer)
  }, [queryClient])

  return data?.data?.badge && data?.data?.badge > 0 ? (
    <Badge count={data?.data?.badge} size={'small'} rootClassName={'badge anticon'}>
      <ExclamationCircleOutlined />
    </Badge>
  ) : (
    <ExclamationCircleOutlined />
  )
}

function App() {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={spaceDataProvider(BACKEND_URL, axiosInstance)}
            notificationProvider={useNotificationProvider}
            authProvider={authProvider(axiosInstance)}
            i18nProvider={i18nProvider}
            routerProvider={routerBindings}
            accessControlProvider={accessControlProvider}
            resources={[
              {
                name: 'dashboards',
                meta: {
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: 'dashboard',
                list: '/dashboard',
                meta: {
                  parent: 'dashboards',
                },
              },
              {
                name: 'partners_dashboard',
                list: '/dashboard/partners',
                meta: {
                  parent: 'dashboards',
                },
              },
              {
                name: 'merchants_dashboard',
                list: '/dashboard/merchants',
                meta: {
                  parent: 'dashboards',
                },
              },
              {
                name: 'events',
                list: '/events',
                meta: { icon: <HistoryOutlined />, },
              },
              {
                name: 'gateways',
                list: '/gateways',
                icon: <BankOutlined />,
              },
              {
                name: 'payment_requisites',
                list: '/payment_requisites',
                create: '/payment_requisites/create',
                edit: '/payment_requisites/edit/:id',
                meta: {
                  icon: <CreditCardOutlined />,
                },
              },
              {
                name: 'merchants',
                list: '/merchants',
                edit: '/merchants/edit/:id',
                show: '/merchants/show/:id',
                create: '/merchants/create',
                meta: {
                  icon: <ShopOutlined />,
                },
              },
              {
                name: 'users',
                list: '/users',
                show: '/users/show/:id',
                edit: '/users/edit/:id',
                create: '/users/create',
                meta: {
                  icon: <TeamOutlined />,
                },
              },
              {
                name: 'sms',
                list: '/sms',
                meta: {
                  icon: <MessageOutlined />,
                },
              },
              {
                name: 'operations',
                list: '/operations',
                create: '/operations/create',
              },
              {
                name: 'payouts',
                list: '/payouts',
                meta: {
                  icon: <DoubleRightOutlined />,
                },
              },
              {
                name: 'disputes',
                list: '/disputes',
                show: '/disputes/show/:id',
                meta: {
                  icon: <DisputesBadge />,
                },
              },
              {
                name: 'partners',
                list: '/partners',
                create: '/partners/create',
                show: '/partners/show/:id',
                edit: '/partners/edit/:id',
                meta: {
                  icon: <PartitionOutlined />,
                },
              },
              {
                name: 'teams',
                list: '/teams',
                create: '/teams/create',
                edit: '/teams/edit/:id',
                meta: {
                  icon: <UsergroupAddOutlined />,
                },
              },
              {
                name: 'team_deposits',
                list: '/team_deposits',
                create: '/team_deposits/create',
                meta: {
                  icon: <PropertySafetyOutlined />,
                },
              },
              {
                name: 'payment_cascades',
                list: '/payment_cascades',
                create: '/payment_cascades/create',
                show: '/payment_cascades/show/:id',
                edit: '/payment_cascades/edit/:id',
                meta: {
                  icon: <FunnelPlotOutlined />,
                },
              },
              {
                name: 'shifts',
                list: '/shifts',
                show: '/shifts/show/:id',
                meta: {
                  icon: <FieldTimeOutlined />,
                },
              },
              {
                name: 'feeds',
                show: '/feeds/show/:id',
                edit: '/feeds/edit/:id',
                meta: {
                  hide: true,
                },
              },
              {
                name: 'feed_items',
                show: '/feed_items/show/:id',
                meta: {
                  hide: true,
                },
              },
              {
                name: 'callbacks',
                list: '/callbacks',
                meta: {
                  icon: <RadiusUpleftOutlined />,
                },
              },
              {
                name: 'reports',
                list: '/reports',
                create: '/reports/create',
                meta: {
                  icon: <FileOutlined />,
                },
              },
              {
                name: 'exports',
                list: '/exports',
                meta: {
                  icon: <FileExcelOutlined />,
                },
              },
              {
                name: 'dev',
                list: '/dev',
                meta: {
                  icon: <ApiOutlined />,
                },
              },
              {
                name: 'webhooks',
                list: '/webhooks',
                meta: {
                  icon: <PullRequestOutlined />,
                },
              },
              {
                name: 'profile',
                list: '/profile',
                meta: {
                  hide: true,
                },
              },
              {
                name: 'agents',
                list: '/agents',
                create: '/agents/create',
                show: '/agents/show/:id',
                meta: {
                  icon: <UserSwitchOutlined />,
                },
              },
              {
                name: 'billing/agreements',
                list: '/agreements',
                create: '/agreements/create',
                show: '/agreements/show/:id',
                meta: {
                  icon: <MoneyCollectOutlined />,
                },
              },
              {
                name: 'billing',
                list: '/billing',
                meta: {
                  icon: <DollarCircleOutlined />,
                },
              },
              {
                name: 'payment_requisite_blocks',
                list: '/payment_requisite_blocks',
                meta: {
                  icon: <GroupOutlined />,
                },
              },
              {
                name: 'access_tokens',
                list: '/access_tokens',
                meta: {
                  hide: true,
                },
              },
              {
                name: 'shifts_summary',
                list: '/shifts/summary',
                meta: {
                  hide: true,
                },
              },
              {
                name: 'scoring/clients',
                list: '/scoring/clients',
                show: '/scoring/clients/show/:id',
                meta: {
                  icon: <UserOutlined />,
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Header={Header}
                      Title={({ collapsed }) => (
                        <ThemedTitleV2
                          collapsed={collapsed}
                          text="Spacepayments"
                          icon={<AppIcon />}
                        />
                      )}
                    >
                      <CanAccess>
                        <Outlet />
                      </CanAccess>
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<NavigateToResource resource="dashboard" />} />
                <Route path="/gateways">
                  <Route index element={<GatewaysList />} />
                </Route>
                <Route path="/payment_requisites">
                  <Route index element={<CardList />} />
                  <Route path="create" element={<CardCreate />} />
                  <Route path="edit/:id" element={<CardEdit />} />
                </Route>
                <Route path="/merchants">
                  <Route index element={<MerchantList />} />
                  <Route path="create" element={<MerchantCreate />} />
                  <Route path="edit/:id" element={<MerchantEdit />} />
                  <Route path="show/:id" element={<MerchantShow />} />
                </Route>
                <Route path="/scoring/clients">
                  <Route index element={<ScoringClientList />} />
                  <Route path="show/:id" element={<ScoringClientShow />} />
                </Route>
                <Route path="/users">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                  <Route path="show/:id" element={<UserShow />} />
                  <Route path="edit/:id" element={<UserEdit />} />
                </Route>
                <Route path="/sms">
                  <Route index element={<SmsList />} />
                </Route>
                <Route path="/operations">
                  <Route index element={<OperationsList />} />
                  <Route path="create" element={<OperationCreate />} />
                </Route>
                <Route path="/payouts">
                  <Route index element={<PayoutsList />} />
                </Route>
                <Route path="/disputes">
                  <Route index element={<DisputesList />} />
                  <Route path="show/:id" element={<DisputeShow />} />
                </Route>
                <Route path="/partners">
                  <Route index element={<PartnersList />} />
                  <Route path="create" element={<PartnerCreate />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>
                <Route path="/teams">
                  <Route index element={<TeamsList />} />
                  <Route path="create" element={<TeamCreate />} />
                  <Route path="edit/:id" element={<TeamEdit />} />
                </Route>
                <Route path="/team_deposits">
                  <Route index element={<TeamDepositsList />} />
                  <Route path="create" element={<TeamDepositCreate />} />
                </Route>
                <Route path="/payment_cascades">
                  <Route index element={<PaymentCascadesList />} />
                  <Route path="create" element={<PaymentCascadeCreate />} />
                  <Route path="show/:id" element={<PaymentCascadeShow />} />
                  <Route path="edit/:id" element={<PaymentCascadeEdit />} />
                </Route>
                <Route path="/shifts">
                  <Route index element={<ShiftsList />} />
                  <Route path="show/:id" element={<ShiftShow />} />
                </Route>
                <Route path="/shifts/summary">
                  <Route index element={<ShiftsSummary />} />
                </Route>
                <Route path="/feeds">
                  <Route path="show/:id" element={<FeedShow />} />
                  <Route path="edit/:id" element={<FeedEdit />} />
                </Route>
                <Route path="/feed_items">
                  <Route path="show/:id" element={<FeedItemShow />} />
                </Route>
                <Route path="/callbacks">
                  <Route index element={<CallbacksList />} />
                </Route>
                <Route path="/events">
                  <Route index element={<EventsList />} />
                </Route>
                <Route path="/reports">
                  <Route index element={<ReportsList />} />
                  <Route path="create" element={<ReportCreate />} />
                </Route>
                <Route path="/exports">
                  <Route index element={<ExportsList />} />
                </Route>
                <Route path="/payment_requisite_blocks">
                  <Route index element={<PaymentRequisiteBlocksList />} />
                </Route>
                <Route path="/agents">
                  <Route index element={<AgentsList />} />
                  <Route path="create" element={<AgentCreate />} />
                  <Route path="show/:id" element={<AgentShow />} />
                </Route>
                <Route path="/agreements">
                  <Route index element={<AgreementsList />} />
                  <Route path="create" element={<AgreementCreate />} />
                  {/* <Route path="show/:id" element={<AgentShow />} /> */}
                </Route>
                <Route path="/profile">
                  <Route index element={<ProfileShow />} />
                </Route>
                <Route path="/dashboard">
                  <Route index element={<DashboardPage />} />
                </Route>
                <Route path="/dashboard/partners">
                  <Route index element={<PartnersDashboardPage />} />
                </Route>
                <Route path="/dashboard/merchants">
                  <Route index element={<MerchantsDashboardPage />} />
                </Route>
                <Route path="/billing">
                  <Route index element={<BillingPage />} />
                </Route>
                <Route path="/dev">
                  <Route index element={<DevPage />} />
                </Route>
                <Route path="/webhooks">
                  <Route index element={<WebhooksList />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
              <Route element={<Outlet />}>
                <Route path="/cashin">
                  <Route path=":id" element={<CashinPage />} />
                </Route>
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  )
}

export default App
