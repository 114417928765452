import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, Spin, Row, Col } from 'antd';
import { useNotification, useApiUrl, } from '@refinedev/core'
import { IOperation, operationEcomStatus, operationStatus } from 'interfaces';

interface EcomProps {
  id: IOperation["id"]
  status: operationStatus
  ecom_status: operationEcomStatus
}

interface EcomParams {
  number: string
  month: string
  year: string
  cvv: string
  otp: string
}

export const Ecom: React.FC<EcomProps> = ({ status, ecom_status, id }) => {
  const { open } = useNotification();

  const apiUrl = useApiUrl()
  const submitCardInfoUrl = `${apiUrl}/cashins/${id}/fill_from`;
  const confirmEcomOtpUrl = `${apiUrl}/cashins/${id}/enter_ecom_confirmation`;
  const resendEcomOtpUrl = `${apiUrl}/cashins/${id}/resend_ecom_confirmation`;
  const statusUrl = `${apiUrl}/cashins/${id}`;

  const [isLoading, setIsLoading] = useState(false);
  const [statusCheckInterval, setStatusCheckInterval] = useState<NodeJS.Timeout | null>(null);

  const [operationStatus, setOperationStatus] = useState(status);
  const [ecomStatus, setEcomStatus] = useState(ecom_status);
  const [ecomParams, setEcomParams] = useState<EcomParams>({
    number: '',
    month: '',
    year: '',
    cvv: '',
    otp: '',
  })

  useEffect(() => {
    if (operationStatus === 'manually_approved' || operationStatus === 'auto_approved') {
      open && open({ message: 'Операция завершена успешно', type: 'success' })
      setIsLoading(false)
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval)
        setStatusCheckInterval(null)
      }
    }
  }, [operationStatus])

  useEffect(() => {
    switch (ecomStatus) {
      case 'filled_from':
      case 'ecom_confirmation_entered':
      case 'ecom_confirmation_resent':
        setIsLoading(true)
        if (!statusCheckInterval) { setStatusCheckInterval(setInterval(checkStatus, 3000)) }
        break;
      default:
        setIsLoading(false)
        if (statusCheckInterval) {
          clearInterval(statusCheckInterval)
          setStatusCheckInterval(null)
        }
        break;
    }
  }, [ecomStatus])

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEcomParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkStatus = async () => {
    try {
      const response = await fetch(statusUrl);
      const data = await response.json();

      setOperationStatus(data?.status)
      setEcomStatus(data?.ecom_status)

    } catch (error) {
      open && open({ message: 'Ошибка при проверке статуса', type: 'error' })
      setIsLoading(false)
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval)
        setStatusCheckInterval(null)
      }
    }
  };

  const handleSubmit = async () => {
    if (
      ecomParams.number &&
      ecomParams.month &&
      ecomParams.year &&
      ecomParams.cvv
    ) {
      setIsLoading(true)

      try {
        const response = await fetch(submitCardInfoUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            number: ecomParams.number,
            month: ecomParams.month,
            year: ecomParams.year,
            cvv: ecomParams.cvv,
          }),
        })

        if (response.ok) {
          checkStatus()
        } else {
          const errorData = await response.json();
          open && open({ message: errorData.message || 'Ошибка отправки данных', type: 'error' });
          setIsLoading(false)
        }
      } catch (error) {
        open && open({ message: 'Ошибка сети', type: 'error' })
        setIsLoading(false)
      }
    } else {
      open && open({ message: 'Пожалуйста, заполните все поля', type: 'error' })
    }
  };

  const handleOtpSubmit = async () => {
    if (ecomParams.otp) {
      setIsLoading(true)

      try {
        const response = await fetch(confirmEcomOtpUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ otp: ecomParams.otp }),
        })

        if (response.ok) {
          checkStatus()
        } else {
          const errorData = await response.json()
          open && open({ message: errorData.message || 'Ошибка отправки OTP', type: 'error' })
          setIsLoading(false);
        }
      } catch (error) {
        open && open({ message: 'Ошибка сети', type: 'error' })
        setIsLoading(false)
      }
    } else {
      open && open({ message: 'Введите OTP', type: 'error' })
    }
  };

  return (
    <Card
      style={{
        position: 'relative',
        backgroundColor: 'rgb(240, 243, 248)',
        width: 350,
        minHeight: 218,
      }}
    >
      {isLoading &&
        <Spin
          size="large"
          tip='Ожидание подтверждения ...'
          style={{
            position: 'absolute',
            width: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 5,
          }}
        />
      }

      {ecomStatus === 'initialized' && (
        <Form layout="vertical">
          <Form.Item label="Номер карты">
            <Input
              name="number"
              value={ecomParams.number}
              onChange={handleFormChange}
              placeholder="1234 5678 9012 3456"
              disabled={isLoading}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Месяц">
                <Input
                  name="month"
                  value={ecomParams.month}
                  onChange={handleFormChange}
                  placeholder="MM"
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Год">
                <Input
                  name="year"
                  value={ecomParams.year}
                  onChange={handleFormChange}
                  placeholder="YYYY"
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="CVV">
                <Input.Password
                  name="cvv"
                  value={ecomParams.cvv}
                  onChange={handleFormChange}
                  placeholder="CVV"
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 10, }}>
            <Button
              type="primary"
              onClick={handleSubmit}
              block
              disabled={isLoading}
            >
              {'Отправить'}
            </Button>
          </Form.Item>
        </Form>
      )}

      {(ecomStatus === 'ecom_confirmation_needed' || ecomStatus === 'ecom_confirmation_failed') && (
        <Form layout="vertical">
          <Form.Item
            label={ecomStatus === 'ecom_confirmation_failed' ? 'Введите КОД еще раз' : 'Введите КОД'}
          >
            <Input
              name='otp'
              value={ecomParams.otp}
              onChange={handleFormChange}
              placeholder="Введите OTP"
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 10, }}>
            <Button
              type="primary"
              onClick={handleOtpSubmit}
              block
              disabled={isLoading}
            >
              {'Отправить OTP'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

