import React, { useState } from 'react'
import { Timer } from 'components/timer'
import { GenericResponse, IOperation, IPaymentRequisite } from 'interfaces'
import { Typography } from "antd";
import './styles.css'

import { ActionsButtons } from './ActionsButtons'
import { BankCard } from './BankCard'
import { Sbp } from './Sbp'
import { Ecom } from './Ecom'
import { useApiUrl, useCustomMutation, useInvalidate } from "@refinedev/core";

const { Text, Paragraph } = Typography

const Header: React.FC<{
  payment_requisite: IPaymentRequisite
  amount: string
  currency: string
}> = ({
  payment_requisite,
  amount,
  currency,
}) => {
    return (
      <Paragraph>
        {payment_requisite.ecom ? (
          <Text>Оплатите </Text>
        ) : (
          <Text>Переведите </Text>
        )}
        <Text style={{ fontSize: 16 }}>
          {`${Number(amount)}`} {`${currency}`}
        </Text>
      </Paragraph>
    )
  }

const Requisite: React.FC<{
  payment_requisite: IPaymentRequisite
}> = ({
  payment_requisite,
}) => {
    return (
      <>
        {payment_requisite.card_number && (
          <>
            <Paragraph>
              <Text>На эту карту 👇</Text>
            </Paragraph>
            <BankCard payment_requisite={payment_requisite} />
          </>
        )}
        {payment_requisite.sbp && (
          <>
            <Paragraph>
              <Text>По номеру телефона 👇</Text>
            </Paragraph>
            <Sbp payment_requisite={payment_requisite} />
          </>
        )}
      </>
    )
  }

export const CreatedOperation: React.FC<IOperation> = ({
  id,
  amount,
  payment_requisite,
  actions,
  expires_at,
  status,
  ecom_status,
  merchant
}) => {
  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()
  const [isLoading, setIsLoading] = useState(false)

  const invalidateOperation = () => {
    invalidate({
      resource: 'cashins',
      invalidates: ['detail'],
      id,
    })
  }

  const onRenew = () => {
    mutate(
      {
        url: `${apiUrl}/cashins/${id}/renew`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {
          setIsLoading(false)
        },
        onSuccess: (data, variables, context) => {
          setIsLoading(false)
          invalidateOperation()
        },
      }
    )
  }

  const canRenew = !!actions.find((item) => item.code === 'renew')

  return (
    <>
      <Header
        payment_requisite={payment_requisite}
        amount={amount}
        currency={merchant.currency}
      />
      {payment_requisite.ecom ? (
        <>
          <Ecom status={status} ecom_status={ecom_status} id={id} />
          {expires_at &&
            <Paragraph style={{ marginTop: 10 }}>
              <Timer
                expiresAt={expires_at}
                onExpire={invalidateOperation}
                canRenew={false}
                onRenew={onRenew}
                renewButtonIsLoading={isLoading}
              />
            </Paragraph>
          }
        </>
      ) : (
        <>
          <Requisite payment_requisite={payment_requisite} />
          <Paragraph style={{ marginTop: 10 }}>
            <Timer
              expiresAt={expires_at}
              onExpire={invalidateOperation}
              canRenew={canRenew}
              onRenew={onRenew}
              renewButtonIsLoading={isLoading}
            />
          </Paragraph>
          <ActionsButtons id={id} actions={actions} />
        </>
      )}
    </>
  )
}
