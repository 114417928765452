import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { EditButton, Show } from '@refinedev/antd'
import { Col, Descriptions, Row, Space, Statistic, Tabs, TabsProps, Typography } from 'antd'

import { IMerchant } from 'interfaces'
import { UsersList } from './UsersList'
import { CardsList } from './CardsList'
import { OperationsList } from './OperationsList'
import { ReportsList } from './ReportsList'
import { formattedCorrectedPercent, formattedCurrencyAmount } from 'utils'

import CreateOutcomeOperationButton from 'components/CreateOutcomeOperationButton'
import CreateRefillOperationButton from 'components/CreateRefillOperationButton'
import { AgreementsList } from './agreements_list'
import { Dev } from './dev'
import BooleanTag from 'components/BooleanTag'
import { GatewayType } from 'components/GatewayType'

const { Text } = Typography

export const MerchantShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IMerchant>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: canListUsers } = useCan({
    resource: 'users',
    action: 'list',
  })

  const { data: canListCards } = useCan({
    resource: 'payment_requisites',
    action: 'list',
  })

  const { data: canCreateOutcomeOperation } = useCan({
    resource: 'operations',
    action: 'create_outcome',
  })

  const { data: canCreateRefillOperation } = useCan({
    resource: 'operations',
    action: 'create_refill',
  })

  const { data: canListOperations } = useCan({
    resource: 'operations',
    action: 'list',
  })

  const { data: canListReports } = useCan({
    resource: 'reports',
    action: 'list',
  })

  const { data: canListAgreements } = useCan({
    resource: 'billing/agreements',
    action: 'list',
  })

  const { data: canViewDev } = useCan({
    resource: 'merchants',
    action: 'dev',
  })

  const { data: canChangeAntifraudEnabled } = useCan({
    resource: 'merchants',
    action: 'change_antifraud_enabled',
  })

  const { data: canChangeDisputesEnabled } = useCan({
    resource: 'merchants',
    action: 'change_disputes_enabled',
  })

  const { data: canChangeAurisShopId } = useCan({
    resource: 'merchants',
    action: 'change_auris_shop_id',
  })

  const headerButtons = () => {
    if (!record) {
      return
    }
    return (
      <>
        <EditButton
          resource="merchants"
          recordItemId={record.id}
          title={t('buttons.edit')}
        />
      </>
    )
  }

  let tabsItems: TabsProps['items'] = []
  canListUsers?.can &&
    tabsItems.push({
      key: '1',
      label: t('merchants.titles.users'),
      children: record && !isLoading ? <UsersList merchantId={record.id} /> : null,
    })
  canListCards?.can &&
    tabsItems.push({
      key: '2',
      label: t('merchants.titles.cards'),
      children: record && !isLoading ? <CardsList merchantId={record.id} /> : null,
    })
  canListOperations?.can &&
    tabsItems.push({
      key: '3',
      label: t('merchants.titles.operations'),
      children: record && !isLoading ? <OperationsList merchantId={record.id} /> : null,
    })
  canListReports?.can &&
    tabsItems.push({
      key: '4',
      label: t('merchants.titles.reports'),
      children: record && !isLoading ? <ReportsList merchantId={record.id} /> : null,
    })
  canListAgreements?.can &&
    tabsItems.push({
      key: '5',
      label: t('merchants.titles.agreements'),
      children: record && !isLoading ? <AgreementsList merchantId={record.id} /> : null,
    })
  canViewDev?.can &&
    tabsItems.push({
      key: '6',
      label: t('merchants.titles.dev'),
      children: record && !isLoading ? <Dev merchantId={record.id} /> : null,
    })

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.name || ''}
        headerButtons={headerButtons}
      >
        <Row>
          <Col span={24} lg={12} style={{marginBottom: 20,}}>
            <Space wrap style={{ alignItems: 'center' }}>
              <Statistic
                title={
                  <>
                    {t('merchants.fields.balance')}
                    {record && canCreateOutcomeOperation?.can && (
                      <CreateOutcomeOperationButton merchant={record} />
                    )}
                    {record && canCreateRefillOperation?.can && (
                      <CreateRefillOperationButton merchant={record} />
                    )}
                  </>
                }
                value={
                  record && formattedCurrencyAmount(record.balance, 2, record.currency)
                }
                precision={2}
              />
            </Space>
          </Col>
          <Col span={24} lg={12}>
            <Descriptions column={1}>
              <Descriptions.Item label="Webhook URL">
                {record?.webhook_url}
              </Descriptions.Item>
              <Descriptions.Item label="Webhook secret">
                {record?.webhook_secret}
              </Descriptions.Item>
              {record?.legacy_webhook && (
                <Descriptions.Item label="Legacy webhook">
                  <BooleanTag value={record?.legacy_webhook} />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Success redirect URL">
                {record?.success_redirect_url}
              </Descriptions.Item>
              <Descriptions.Item label="Failure redirect URL">
                {record?.failure_redirect_url}
              </Descriptions.Item>
              <Descriptions.Item label="Back URL">{record?.back_url}</Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.interval_in_minutes')}>
                {record?.interval_in_minutes}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.after_ecom_timeout_in_minutes')}>
                {record?.after_ecom_timeout_in_minutes}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.fill_from_timeout_in_minutes')}>
                {record?.fill_from_timeout_in_minutes}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.confirm_ecom_timeout_in_minutes')}>
                {record?.confirm_ecom_timeout_in_minutes}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.payouts_sla_in_minutes')}>
                {record?.payouts_sla_in_minutes}
              </Descriptions.Item>
              {canChangeAntifraudEnabled?.can && (
                <Descriptions.Item label={t('merchants.fields.antifraud_enabled')}>
                  <BooleanTag value={record?.antifraud_enabled} />
                </Descriptions.Item>
              )}
              {canChangeAurisShopId?.can && (
                <Descriptions.Item label={t('merchants.fields.auris_shop_id')}>
                  {record?.auris_shop_id}
                </Descriptions.Item>
              )}
              {canChangeDisputesEnabled?.can && (
                <Descriptions.Item label={t('merchants.fields.disputes_enabled')}>
                  <BooleanTag value={record?.disputes_enabled} />
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t('merchants.fields.auto_approve_limit')}>
                {record?.auto_approve_limit
                  ? formattedCurrencyAmount(
                      record?.auto_approve_limit,
                      2,
                      record?.currency
                    )
                  : '–'}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.timezone')}>
                {record?.timezone}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.currency')}>
                {record?.currency}
              </Descriptions.Item>
              <Descriptions.Item label={t('merchants.fields.telegram_chat_id')}>
                {record?.telegram_chat_id}
              </Descriptions.Item>                       
            </Descriptions>
            {canListAgreements?.can && <Descriptions column={1} layout='vertical'>           
              <Descriptions.Item label={t('merchants.fields.agreements_income')}>
                <div>
                  {record?.agreements_income?.length ? (
                    record?.agreements_income.map((agreement) => (
                      <Text
                        code
                        ellipsis
                        style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                      >
                        <Space>
                          {agreement?.gateway ? (
                            <Space>
                              <GatewayType type={agreement.gateway.payment_type} />
                              {agreement.gateway.currency}
                              {agreement.gateway.name}
                            </Space>
                          ) : <Text>Гейтвей не указан</Text>}
                          {formattedCorrectedPercent(agreement.percent)}
                        </Space>
                      </Text>
                    ))) : <Text>Нет соглашений</Text>
                  }
                </div>
              </Descriptions.Item>   
              <Descriptions.Item label={t('merchants.fields.agreements_payout')}>
                <div>
                  {record?.agreements_payout?.length ? (
                    record?.agreements_payout.map((agreement) => (
                      <Text                  
                        code
                        ellipsis
                        style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                      >
                        <Space>
                          {agreement?.gateway ? (
                            <Space>
                              <GatewayType type={agreement.gateway.payment_type} />
                              {agreement.gateway.currency}
                              {agreement.gateway.name}
                            </Space>
                          ) : <Text>Гейтвей не указан</Text>}
                          {formattedCorrectedPercent(agreement.percent)}
                        </Space>
                      </Text>
                    ))) : <Text>Нет соглашений</Text>              
                  }
                </div>
              </Descriptions.Item>   
            </Descriptions>}
          </Col>
        </Row>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
